.form-container{
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 4px;
  }
  
  .form-row{
    margin-bottom: 10px;
  }
  
  label{
    display: block;
    margin-bottom: 5px;
  }
  
  /* input,
  .react-select-container{
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
  } */
  
  /* button{
    padding: 8px 16px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
  }
  
  button:hover{
    background-color: #45a049;
  } */
  
  h2{
    margin-top: 20px;
  }
  
  ul{
    margin: 0;
    padding: 0;
  }
  
  li{
    list-style-type: none;
  }

  .create_user_button {
    background-color: #3366cc;
    color: white;
    padding: 5px 5px;
    margin-bottom: 10px;
    font-size : 10px;
    border: none;
    border-radius: 5px;
    width: 125px;
    height: 29px;
  }

  .userrolefilter {
    color : rgb(121, 194, 238);
    font-size: 12px;
  }

  .selectRoles {
    font-size: 12px;
    margin-bottom: 10px;
    height: 20px;
    border: none;
    outline: none;
    border-bottom: groove;
  }

  .selectusers {
    margin-right: 5px;
    padding: 2px;
    margin-bottom: 10px;
    font-size: 12px;
    height: 24px;
    outline: none;
    border: none;
    border-bottom: groove;
  }

  .createusermodalheading {
    color: rgb(6, 126, 167);
    font-size: 12px;
  }

  .labelFontStyle {
    font-size: 10px;
  }

  .emailfield {
    width: 600px;
    padding: 5px;
    border: none;
    outline: none;
    border-bottom: groove;
  }

  .userselectdropdown {
    border: none;
    outline: none;
    border-bottom: groove;
    width: 200px;

  }

  .usercreatebutton {
    outline: none;
    border: none;
  }

  .usercancel {
    margin-right: 10px;
    background-color: #CCCCCC; 
    color: black;
    padding: 5px 10px;
    font-size: 10px;
  }
  
  .useradd {
    background-color: rgb(70, 201, 223);
    color: white;
    padding: 5px 10px ;
    font-size: 10px;
    margin-bottom: 10px;
  }