/* Container for the download progress */
.download-progress-container {
  width: 100%;
  background-color: #F5F7EF;
  margin-top: 10px;
  padding: 15px 0;
  text-align: center;
  border-radius: 5px;
}
.download-progress-header {
  font-size: 1.0em;
  font-weight: bold;
  padding-bottom: 10px;
  background-color: #F5F7EF;  
  border-radius: 5px;
  /* #e9e8e8; */
}
.download-progress-bar-wrapper {
  width: 95%;
  margin: 0 auto;
}
.download-progress-bar {
  position: relative;
  height: 16px;
  background-color: white;
  border: 1px solid black;
  border-radius: 1px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
 
.download-progress-bar .progress {
  width: 0;
  height: 100%;
  background-color: #20477e;
  transition: width 0.3s ease;
}
.loader-container {
  font-size: 1.2em;
  margin-top: 20px;
}
.loader-container::after {
  display: inline-block;
  margin-left: 10px;
}
@keyframes loading {
  0% { content: "Loading..."; }
  50% { content: "Loading"; }
  100% { content: "Loading..."; }
}