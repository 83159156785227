.modal-content-table
{
	font-family: Arial, Helvetica, sans-serif;
	font-size: 10pt;
	/* background-color: #FFF; */
	background-color: #DDDDDD !important;
	background-image: url('../../../images/topnav.gif');
	background-repeat: repeat-x;
	margin: 0px 0px 0px 0px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
   
.pagetitle
{
	font-size: 15px;
	vertical-align: middle;
	font-weight: bold;
	font-family: Arial, Helvetica, sans-serif;
	color: #000;
	line-height: 15px;
}
   
.instructions
{
	font-size: 11px;
	vertical-align: left;
	color: #000;
	font-family: verdana, arial, sans serif;
	font-weight: 600;
	padding: 0 5px 0 5px;
	word-break: break-all;
}

.numbers
{
	font-size: 10px;
	vertical-align: left;
	color: #000;
	font-family: verdana, arial, sans serif;
	font-weight: 800;
	padding: 5px;
	background-color: #E9EAEC;
}



.formButtonUploadGo
{
	font-size: 11px;
	vertical-align: middle;
	font-family: Arial, Helvetica, sans-serif;
	background-color: #407447;
	color: #FFF;
}

.formButton
{
	font-size: 11px;
	vertical-align: middle;
	font-family: Arial, Helvetica, sans-serif;
	background-color: #DDD;
	color: #000;
}

.closeButton
{
	border-style: none;
    background-color: white;
    color: blue;
    cursor: pointer;
	text-decoration: underline;
}

/**
* styles for sms popup
*/

.crumb
{
	font-size: 13px;
	vertical-align: left;
	color: #000;
	font-family: verdana, arial, sans serif;
	background-color: #F5F7EF;
	border-color: blue;
	padding: 2px;
	border-width: 1px;
	border-style: dotted;
}

.lightFrameColor { background-color: #CCC; }

.tableTitle
{
	font-size: 90%;
	vertical-align: middle;
	font-weight: bold;
	font-family: Arial, Helvetica, sans-serif;
	color: #000;
	background-color: #C9C9DA;
}

.tableTitleBg       { background-color: #C9C9DA; }

.alert
{
	font-size: 90%;
	vertical-align: middle;
	font-weight: bold;
	color: #900;
}

.tableContent
{
	font-size: 90%;
	vertical-align: middle;
	font-family: Arial, Helvetica, sans-serif;
	color: #000;
	background-color: #FFFFFF;
}

.tableContentBg     { background-color: #FFFFFF; }

.tableContentTop
{
	font-size: 90%;
	vertical-align: top;
	font-family: Arial, Helvetica, sans-serif;
	color: #000;
	background-color: #FFFFFF;
}

.formTextFull
{
	font-size: 64%;
	vertical-align: middle;
	border-width: 1px;
	border-style: solid;
	font-family: Arial, Helvetica, sans-serif;
	width: 95%;
}
.formDropdown      { font-size: 80%; vertical-align: middle; border-width: 1px; font-family: Arial, Helvetica, sans-serif; }
.formRadio         { font-size: 80%; vertical-align: middle; color: #333333; }
.formSMSButtonGo      { font-size: 80%; vertical-align: middle; font-family : Arial, Helvetica, sans-serif; background-color: #407447; color: #FFFFFF; }
.formSMSButtonGo.disabled {background-color: #dddddd; color: #444141;cursor: not-allowed;opacity: 0.6;}
.formSMSTextFull      { font-size: 80%; vertical-align: middle; border-width: 1px; border-style: solid; font-family: Arial, Helvetica, sans-serif; width: 95%; }
.formButtonStop    { font-size: 64%; vertical-align: middle; font-family : Arial, Helvetica, sans-serif; background-color: #992D2F; color: #FFFFFF; }
.copyright
{
	font-size: 10px;
	vertical-align: left;
	color: #000;
	font-family: verdana, arial, sans serif;
}

.ReactModal__Content--after-open {
	max-width: 640px;
    height:510px;
    margin: auto;
	padding: 0px !important;
	border-radius: 15px !important;
	/* background-color: #DDDDDD !important; */

}

.smsTable tr td {line-height: 10px;}

.create-new-folder {

	position:absolute; 
	left:0px; 
	top:35px; 
	width:90px; 
	height:207px; 
	z-index:0; 
	background-color: #E9EAEC; 
	/* layer-background-color: #E9EAEC;  */
	border: 1px none #000000;
}

.create-new-folder-input {
	width:400px; 
	height:15px; 
	line-height:12px; 
	font-family: verdana; 
	font-size:11px; 
	padding-top: 2px; 
	padding-right: 0px; 
	padding-bottom: 0px; 
	padding-left: 0px; 
	margin-top: 10px; 
	margin-right: 0px; 
	margin-bottom: 10px; 
	margin-left: 0px;
}

.no-user-dir-button {
	border : none;
	background: none;
	cursor: pointer;
	font-size: 11px;
	font-weight: bold;
	color: blue;
	font-family: verdana, arial, sans serif;
}

.preview-iframe {
	position:fixed; 
	top:0; 
	left:0; 
	bottom:0; 
	right:0; 
	width:100%; 
	height:100%; 
	border:none; 
	margin:0; 
	padding:0; 
	overflow:hidden; 
	z-index:999999;
}
.create-new-folder-ErrorMsg{
    color: red ;
}

.delete-folder {
    position: absolute; 
    left: 0px; 
    top: 35px; 
	width:15%; 
	height: 100%;  /* Height will expand based on content */
    max-height: calc(100vh - 35px); /* Limit height to the viewport, accounting for top offset */
    z-index: 0; 
    background-color: #E9EAEC; 
    overflow-y: auto; /* Scroll if content exceeds max height */
}

.delete-folder-color {
  background-color: #E9EAEC;  
}

.delete-icon{
	margin-left:16px ;
	border:2px;
	border-radius: 8px;
}
