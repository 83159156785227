.logincontainer{
    background: rgba(59, 128, 255,.3);
    width: 100%;
    height: 100vh;
}
.loginform{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 400px;
    background: rgba(256, 256, 256,.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;}
.loginform h1{
    font-weight: 500;
}
.loginform input{
    padding: 10px 40px;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    border-radius: 10px;
    /* outline: 1px solid orange; */
    border: none;
}
.loginform label{
    font-size: 1rem;
}
.loginbtn{
    padding: 1rem 3rem;
    background: rgb(59, 128, 255);
    color: white;
    border: none;
    border-radius: 15px;
    margin-bottom: 1rem;
}
.signupbtn{
    padding: 0.5rem 3rem;
    color: rgb(59, 128, 255);
    border: 1px solid rgb(59, 128, 255);
    background-color: transparent;
    border-radius: 15px;
    margin-bottom: 1rem;
}