.ssoredirect-container {

    width: 50%;
    min-width: 300px;
    margin: 100px auto;
    text-align: center;

}

.btn-ssologin {
    padding: 1rem;
    margin: 10px auto;
    background-color: #0d6efd;
    border: none;
    border-radius: 5px;
}

.ssoredirect-container {

    width: 50%;
    min-width: 300px;
    margin: 100px auto;
    text-align: center;

}

.btn-ssologin {
    padding: 1rem;
    margin: 10px auto;
    background-color: #0d6efd;
    border: none;
    border-radius: 5px;
}

.info-box {
    border: 3px solid  #31708f;
    background-color: #d9edf7;
    padding: 10px; 
    margin-bottom: 10px; 
    color: #31708f; 
    text-align: center; 
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 4px;
}
